import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CodeIcon from '@mui/icons-material/Code';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

const PURPLE = '#302b63';

const ProjectBackground = styled(Box)({
  backgroundColor: PURPLE,
  minHeight: '100vh',
  color: 'white',
  paddingTop: '32px',
  paddingBottom: '32px',
});

const ProjectCard = styled(Card)({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  height: '100%',
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const FeatureIcon = styled(CheckCircleOutlineIcon)({
  color: 'white',
});

const SectionTitle = styled(Typography)({
  color: '#66FCF1',
  marginBottom: '24px',
  fontWeight: 'bold',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-8px',
    left: 0,
    width: '60px',
    height: '4px',
    backgroundColor: '#66FCF1',
  },
});

const ContentSection = styled(Box)({
  marginBottom: '48px',
});

const ModalContent = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '1200px',
  backgroundColor: PURPLE,
  boxShadow: '0 24px 48px rgba(0, 0, 0, 0.3)',
  padding: '32px',
  outline: 'none',
  borderRadius: '8px',
  color: 'white',
});

export default function Project1() {
  const [openModal, setOpenModal] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const handleOpenModal = (imageSrc) => {
    setModalImage(imageSrc);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const features = [
    "AI-powered meal analysis through image recognition",
    "Personalized nutritional recommendations",
    "Intelligent chat interface with AI nutritionist (Pal)",
    "Simplified meal logging with automatic macro tracking",
    "Goal setting and progress monitoring with visual feedback",
    "Customized recipe suggestions based on preferences and goals",
    "Integration with fitness tracking devices",
    "Social sharing and community support features",
  ];

  const technologies = [
    "React Native for cross-platform mobile development",
    "Expo for streamlined React Native workflow",
    "Appwrite for backend services and user authentication",
    "OpenAI's GPT-4 for intelligent conversation and image analysis",
    "TailwindCSS for responsive and customizable UI design",
    "Custom hooks for state management and API integration",
    "Axios for efficient API communication",
    "AsyncStorage for local data persistence",
  ];

  const challenges = [
    "Implementing accurate image recognition for diverse food items",
    "Designing an intuitive user interface for complex nutritional data",
    "Integrating and fine-tuning the AI chatbot for personalized advice",
    "Ensuring data privacy and security for sensitive user information",
    "Optimizing app performance with heavy AI computations",
    "Balancing feature richness with app simplicity and ease of use",
  ];

  const learnings = [
    "Deepened understanding of AI integration in mobile applications",
    "Improved skills in React Native and state management",
    "Gained experience in working with large language models like GPT-4",
    "Enhanced knowledge of nutritional science and fitness tracking",
    "Developed problem-solving skills for complex user interactions",
    "Learned to balance technical features with user experience",
    "Improved project management skills for large-scale app development",
    "Gained insights into user behavior and preferences in health apps",
  ];

  return (
    <ProjectBackground>
      <Container maxWidth="lg">
        <Button
          component={Link}
          to="/"
          startIcon={<ArrowBackIcon />}
          sx={{ mb: 4, color: 'white' }}
        >
          Back to Home
        </Button>

        <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 6, color: '#66FCF1' }}>
          FitPal: AI-Powered Nutrition Companion
        </Typography>

        <ContentSection>
          <SectionTitle variant="h4" gutterBottom>
            Project Overview
          </SectionTitle>
          <Typography paragraph>
            FitPal is a culmination of my passion for fitness and technology. As an avid gym-goer and nutrition enthusiast, I've always struggled with the tedious process of tracking meals and managing my fitness goals. This inspired me to create an AI-powered mobile application that simplifies nutrition management and provides personalized fitness guidance.
          </Typography>
          <Typography paragraph>
            The app leverages cutting-edge AI technology to offer features like image-based meal analysis, intelligent nutritional recommendations, and an AI chatbot that serves as a personal nutritionist. My goal was to create a tool that not only tracks but understands and adapts to each user's unique health journey.
          </Typography>
        </ContentSection>

        <ContentSection>
          <SectionTitle variant="h4" gutterBottom>
            Key Features
          </SectionTitle>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <List>
                {features.map((feature, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <FeatureIcon />
                    </ListItemIcon>
                    <ListItemText primary={feature} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <ProjectCard onClick={() => handleOpenModal('/assets/fitpal1.jpeg')}>
                <CardMedia
                  component="img"
                  height="300"
                  image="/assets/fitpal1.jpeg"
                  alt="FitPal Sign In Page"
                />
                <CardContent>
                  <Typography variant="body2">
                    FitPal's welcoming sign-in page
                  </Typography>
                </CardContent>
              </ProjectCard>
            </Grid>
          </Grid>
        </ContentSection>

        <Divider sx={{ my: 4, backgroundColor: 'rgba(255,255,255,0.1)' }} />

        <ContentSection>
          <SectionTitle variant="h4" gutterBottom>
            Technology Stack
          </SectionTitle>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <List>
                {technologies.map((tech, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CodeIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary={tech} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <ProjectCard onClick={() => handleOpenModal('/assets/fitpal2.jpeg')}>
                <CardMedia
                  component="img"
                  height="300"
                  image="/assets/fitpal2.jpeg"
                  alt="FitPal Home Page"
                />
                <CardContent>
                  <Typography variant="body2">
                    Home page before adding any macros
                  </Typography>
                </CardContent>
              </ProjectCard>
            </Grid>
          </Grid>
        </ContentSection>

        <ContentSection>
          <SectionTitle variant="h4" gutterBottom>
            Development Challenges
          </SectionTitle>
          <Typography paragraph>
            Building FitPal was an exciting journey filled with complex challenges that pushed my skills to new limits. Here are some of the major hurdles I overcame:
          </Typography>
          <List>
            {challenges.map((challenge, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <FitnessCenterIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary={challenge} />
              </ListItem>
            ))}
          </List>
        </ContentSection>

        <ContentSection>
          <SectionTitle variant="h4" gutterBottom>
            FitPal in Action
          </SectionTitle>
          <Grid container spacing={2}>
            {[3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
              <Grid item xs={12} sm={6} md={3} key={num}>
                <ProjectCard onClick={() => handleOpenModal(`/assets/fitpal${num}.jpeg`)}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={`/assets/fitpal${num}.jpeg`}
                    alt={`FitPal Feature ${num}`}
                  />
                  <CardContent>
                    <Typography variant="body2">
                      {num === 3 && "Insights section on the home page"}
                      {num === 4 && "AI-powered meal analysis on the home screen"}
                      {num === 5 && "Home page with macros added and progress circles filling"}
                      {num === 6 && "Default Pal page for AI nutritionist interaction"}
                      {num === 7 && "Conversation with Pal, showcasing personalized learning"}
                      {num === 8 && "Recipes page with suggested meals"}
                      {num === 9 && "Detailed recipe view with cooking procedure and macro options"}
                      {num === 10 && "User profile page for personalized settings"}
                    </Typography>
                  </CardContent>
                </ProjectCard>
              </Grid>
            ))}
          </Grid>
        </ContentSection>

        <ContentSection>
          <SectionTitle variant="h4" gutterBottom>
            Key Learnings
          </SectionTitle>
          <Typography paragraph>
            Developing FitPal has been an incredible learning experience. Here are some of the key takeaways from this journey:
          </Typography>
          <List>
            {learnings.map((learning, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <FitnessCenterIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary={learning} />
              </ListItem>
            ))}
          </List>
        </ContentSection>

        <ContentSection>
          <SectionTitle variant="h4" gutterBottom>
            Future Directions
          </SectionTitle>
          <Typography paragraph>
            While FitPal has come a long way, I see numerous opportunities for future enhancements:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FeatureIcon />
              </ListItemIcon>
              <ListItemText primary="Integration with a wider range of wearable devices for more comprehensive health tracking" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FeatureIcon />
              </ListItemIcon>
              <ListItemText primary="Implementing machine learning algorithms for more accurate long-term progress predictions" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FeatureIcon />
              </ListItemIcon>
              <ListItemText primary="Expanding the AI capabilities to include workout planning and form correction" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FeatureIcon />
              </ListItemIcon>
              <ListItemText primary="Developing a web platform for seamless cross-device synchronization" />
            </ListItem>
          </List>
        </ContentSection>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="image-modal"
          aria-describedby="expanded project image"
        >
          <ModalContent>
            <Button onClick={handleCloseModal} sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}>
              <CloseIcon />
            </Button>
            <img
              src={modalImage}
              alt="Expanded view"
              style={{ width: '100%', height: 'auto', maxHeight: '80vh', objectFit: 'contain' }}
            />
          </ModalContent>
        </Modal>
      </Container>
    </ProjectBackground>
  );
}
