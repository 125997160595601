import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CodeIcon from '@mui/icons-material/Code';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Link } from 'react-router-dom';

const ProjectBackground = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(to bottom, #0f0c29, #302b63, #24243e)',
  minHeight: '100vh',
  color: 'white',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const ProjectCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  height: '100%',
}));

const FeatureIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const WhiteTextListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
    color: 'white',
  },
}));

export default function Project2() {
  const features = [
    "Prompt-based video editing commands",
    "Automated timeline management",
    "AI-powered scene detection and segmentation",
    "Intelligent audio-video synchronization",
    "Real-time preview of edits",
    "Advanced effects and transitions library",
  ];

  const technologies = [
    "PyQt5 for the user interface",
    "OpenCV for video processing",
    "FFmpeg for media handling",
    "Librosa for audio analysis",
    "TensorFlow for AI-powered features",
    "Natural Language Processing for prompt interpretation",
  ];

  return (
    <ProjectBackground>
      <Container maxWidth="lg">
        <Button
          component={Link}
          to="/"
          startIcon={<ArrowBackIcon />}
          color="inherit"
          sx={{ mb: 4 }}
        >
          Back to Home
        </Button>

        <Typography variant="h2" component="h1" gutterBottom>
          AI-Powered Video Editor
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom>
              Project Overview
            </Typography>
            <Typography paragraph>
              The AI-Powered Video Editor is an ambitious project I'm currently developing, aiming to revolutionize the way we edit videos. This innovative application leverages artificial intelligence to automate complex video editing tasks through simple, intuitive prompts. By combining advanced AI algorithms with a user-friendly interface, I'm creating a tool that makes professional-grade video editing accessible to everyone.
            </Typography>
            <Typography paragraph>
              The core concept of this project is prompt-based editing. Users can describe their desired edits in natural language, and the AI interprets these prompts to perform complex editing tasks automatically. This approach significantly reduces the learning curve associated with traditional video editing software while maintaining a high degree of control and creativity.
            </Typography>
            <Typography paragraph fontWeight="bold">
              Ongoing Development
            </Typography>
            <Typography paragraph>
              This project is currently in active development. I'm continuously refining the AI's ability to understand and execute editing prompts, expanding the range of possible operations, and optimizing the user interface for an intuitive editing experience. My goal is to create a versatile tool that caters to both novice users looking for quick, automated edits and professionals seeking to streamline their workflow.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <ProjectCard>
              <CardMedia
                component="img"
                height="300"
                image="/assets/project2.jpg"
                alt="AI Video Editor Interface"
              />
              <CardContent>
                <Typography variant="body2">
                  Current interface of the AI-Powered Video Editor
                </Typography>
              </CardContent>
            </ProjectCard>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              Key Features
            </Typography>
            <List>
              {features.map((feature, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <FeatureIcon />
                  </ListItemIcon>
                  <ListItemText primary={feature} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              Technologies Used
            </Typography>
            <List>
              {technologies.map((tech, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <FeatureIcon />
                  </ListItemIcon>
                  <ListItemText primary={tech} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>

        <Box sx={{ mt: 6 }}>
          <Typography variant="h5" gutterBottom>
            Current Development Focus
          </Typography>
          <Typography paragraph>
            As I continue to develop this AI-Powered Video Editor, my current focus areas include:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <AutoFixHighIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Enhancing the natural language processing capabilities for more accurate prompt interpretation" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoFixHighIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Expanding the range of automated editing techniques and effects" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoFixHighIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Optimizing the real-time preview functionality for smoother performance" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoFixHighIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Implementing more advanced AI-driven features like content-aware editing and style transfer" />
            </ListItem>
          </List>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Technical Challenges and Solutions
          </Typography>
          <Typography paragraph>
            Developing an AI-powered video editor presents unique challenges. Here are some of the key technical aspects I'm working on:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CodeIcon color="secondary" />
              </ListItemIcon>
              <WhiteTextListItemText
                primary="Efficient Video Processing"
                secondary="Implementing multi-threading and GPU acceleration for real-time editing capabilities"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CodeIcon color="secondary" />
              </ListItemIcon>
              <WhiteTextListItemText
                primary="Natural Language Understanding"
                secondary="Developing a robust NLP model to accurately interpret diverse editing prompts"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CodeIcon color="secondary" />
              </ListItemIcon>
              <WhiteTextListItemText
                primary="AI-Driven Creative Decisions"
                secondary="Training models to make aesthetic choices in line with user intentions"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CodeIcon color="secondary" />
              </ListItemIcon>
              <WhiteTextListItemText
                primary="User Interface Design"
                secondary="Creating an intuitive interface that balances simplicity with powerful functionality"
              />
            </ListItem>
          </List>
          <Typography paragraph>
            This project aims to represent a significant step forward in the field of video editing, combining cutting-edge AI technology with user-centric design. I'm excited about its potential to democratize video editing and empower creators of all skill levels.
          </Typography>
        </Box>
      </Container>
    </ProjectBackground>
  );
}
