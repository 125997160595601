import React from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Link } from 'react-router-dom';

const ArticleBackground = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(to bottom, #0f0c29, #302b63, #24243e)',
  minHeight: '100vh',
  color: 'white',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const ArticleCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  marginBottom: theme.spacing(4),
}));

const ContentSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export default function Article1() {
  return (
    <ArticleBackground>
      <Container maxWidth="lg">
        <Button
          component={Link}
          to="/"
          startIcon={<ArrowBackIcon />}
          color="inherit"
          sx={{ mb: 4 }}
        >
          Back to Home
        </Button>

        <ArticleCard>
          <CardMedia
            component="img"
            height="400"
            image="/assets/article1.jpg"
            alt="AI and the Future of Work"
          />
          <CardContent>
            <Typography variant="h2" gutterBottom>
              AI and Its Impact on the Future of Work
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              Published on July 23, 2024 | By Christophe Marcellus
            </Typography>
            <Divider sx={{ my: 2, backgroundColor: 'rgba(255,255,255,0.1)' }} />

            <ContentSection>
              <Typography variant="body1" paragraph>
                We're quickly approaching the age where the impossible becomes possible, from self-driving cars to virtual assistants.
                Artificial intelligence (AI) is at the forefront of this transformation, reshaping the way we live and work. Many people fear that AI
                will replace human jobs, but the reality is more nuanced. Every few months new technologies are deployed by large companies that appear
                to complete tasks that were once thought to be impossible for machines. Is there a future where we can coexist with AI in the workplace?
                or will it take over completely? What would be the need for human labour? It'd be deranged to demand a profit-driven company to employ humans,
                who require pay, breaks, and sleep, when a machine can do the same job more efficiently and without any of the aforementioned requirements. A
                study by Mckinsey global institute says that 800 million jobs could be lost worldwide to automation by 2030. The same study says that up to
                375 million people may need to switch occupational categories and learn new skills. In their research, the found that AI has the profound
                impact to deliver additional economic output of around $13 trillion by 2030, boosting global GDP by about 1.2% per year. The impact of AI
                on the future of work is undeniable, and it's essential to understand how to navigate this rapidly changing landscape. Finally, the same study
                states that by 2030, at least 70% of companies will adopt at least one form of AI technology. So, what does this mean for the future of work?
                I have come across three types of respondents to AI. The first group intends to use AI as a clutch to solve all their problems. They no longer
                take time to think about the problem and its solution. They just throw the problem at the AI and expect it to solve it. This group have dangerously
                adopted AI, and are slowly developing cognitive laziness. They no longer aim to upskill themselves, as they believe that AI will solve all their problems.
                The second group rejects AI. They believe that AI is a threat to their jobs and their way of life. They believe that AI will take over the world and their
                livelihoods. They are afraid of AI and are not willing to learn how to use it. The third group is the group that I belong to. This group understands that
                AI is a tool that can be used to solve problems. They understand that AI is not a threat to their jobs, but a tool that can be used to make their jobs easier.
                They have not given up on learning new skills, and they are willing to learn new skills to adopt to the changing world. This view is the most balanced view of AI.
                Life has always been about competition and survival of the fittest. We first only had to compete with other humans, but now we have to compete with machines.
                If that is not enough motivation to learn new skills, I don't know what is. Despite AI being a threat to many jobs, it is also creating new jobs. According to
                an article from Forbes magazine, AI is creating new jobs in the field of data science, machine learning, AI, and cybersecurity. The article also states that AI is
                creating new jobs in the field of AI ethics. AI ethics is the field that is concerned with ensuring that AI is used in a fair and just manner. The introduction of AI
                is also a great opportunity for entrepreneurship. In the past, starting a business was a difficult task. You needed a lot of capital, and you needed to have a lot of
                connections. With the introduction of AI, you can start a business with little to no capital or human resources. You can start a business with just a laptop and an internet connection.
                Many traditional tasks can be automated with AI, and this can save you a lot of time and money. The future is here, the future we saw in movies is here. The future where we can talk to our
                devices and they can understand us is here. The future where we can have self-driving cars is here. The future where we can have robots that can do our jobs is here. There is no going back.
              </Typography>
            </ContentSection>

            <ContentSection>
              <Typography variant="h4" gutterBottom>
                Key Areas of AI's Impact
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <WorkIcon sx={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Job Displacement and Creation"
                    secondary="While AI may automate certain tasks, it's also creating new roles and industries."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <SchoolIcon sx={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Skill Evolution"
                    secondary="The demand for AI-related skills is growing, emphasizing the need for continuous learning."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <TrendingUpIcon sx={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Productivity Enhancement"
                    secondary="AI tools are boosting efficiency across various sectors, from healthcare to finance."
                  />
                </ListItem>
              </List>
            </ContentSection>

            <ContentSection>
              <Typography variant="h4" gutterBottom>
                Preparing for an AI-Driven Future
              </Typography>
              <Typography variant="body1" paragraph>
                As AI continues to evolve, it's essential for individuals and organizations to adapt. This includes:
              </Typography>
              <List>
                <ListItem>
                  <Typography variant="body1">1. Embracing lifelong learning and skill development</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">2. Fostering creativity and emotional intelligence</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">3. Developing AI literacy across all levels of the workforce</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">4. Prioritizing ethical considerations in AI development and deployment</Typography>
                </ListItem>
              </List>
            </ContentSection>

            <ContentSection>
              <Typography variant="h4" gutterBottom>
                Conclusion
              </Typography>
              <Typography variant="body1" paragraph>
                The integration of AI into the workplace is not just inevitable; it's already underway. By understanding its impact and proactively adapting, we can harness the potential of AI to create a more efficient, innovative, and inclusive future of work. The key lies in our ability to work alongside AI, leveraging its strengths while nurturing our uniquely human capabilities.
              </Typography>
            </ContentSection>
          </CardContent>
        </ArticleCard>
      </Container>
    </ArticleBackground>
  );
}
