import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Fade,
  Card,
  CardContent,
  CardMedia,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CodeIcon from '@mui/icons-material/Code';
import BrainIcon from '@mui/icons-material/Psychology';
import ComputerIcon from '@mui/icons-material/Computer';
import BuildIcon from '@mui/icons-material/Build';
import { Link } from 'react-router-dom';

const VideoBackground = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  zIndex: -1,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(15,12,41,0.7)',
  },
}));

const BackgroundVideo = styled('video')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '100%',
  minHeight: '100%',
  width: 'auto',
  height: 'auto',
  transform: 'translateX(-50%) translateY(-50%)',
});

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  minHeight: '100vh',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
}));

const WelcomeBox = styled(Box)(({ theme }) => ({
  background: 'rgba(128, 0, 128, 0.2)',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  display: 'inline-block',
  marginBottom: theme.spacing(2),
}));

const SkillChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
}));

const AstronautImage = styled('img')(({ theme }) => ({
  position: 'fixed',
  right: '5%',
  bottom: '10%',
  width: '30%',
  maxWidth: 400,
  animation: 'float 6s ease-in-out infinite',
  '@keyframes float': {
    '0%, 100%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-20px)' },
  },
  zIndex: 0,
}));

const ProjectCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const ArticleCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const skills = [
  { name: 'AI', icon: <BrainIcon /> },
  { name: 'Machine Learning', icon: <BrainIcon /> },
  { name: 'IT', icon: <ComputerIcon /> },
  { name: 'Web Development', icon: <CodeIcon /> },
  { name: 'Python', icon: <CodeIcon /> },
  { name: 'React', icon: <CodeIcon /> },
  { name: 'Problem Solving', icon: <BuildIcon /> },
  { name: 'Data Analysis', icon: <BrainIcon /> },
  { name: 'Cloud Computing', icon: <ComputerIcon /> },
  { name: 'JavaScript', icon: <CodeIcon /> },
  { name: 'Node.js', icon: <CodeIcon /> },
  { name: 'SQL', icon: <CodeIcon /> },
];

const projects = [
  {
    title: "AI-Powered Nutritionist",
    description: "The Nutritionist is an innovative mobile application that leverages AI to provide detailed nutritional information from food images. This full-stack solution combines advanced image recognition with natural language processing to deliver a user-friendly experience for health-conscious individuals and nutrition enthusiasts",
    image: "/assets/fitpal4.jpeg",
    link: "/project1"
  },
  {
    title: "Ai Powered Video Editor",
    description: "This project is a sophisticated video editing application built using PyQt5, offering a range of features for video manipulation, audio visualization, and timeline-based editing. The application provides a user-friendly interface for video editing tasks, combining visual elements with precise control over media content.",
    image: "/assets/project2.jpg",
    link: "/project2"
  },
  {
    title: "AI-Powered Social Media Manager",
    description: "This project aims to develop a comprehensive social media management platform that allows users to connect and manage their social media accounts, schedule posts, and analyze engagement metrics. The platform integrates with popular social media networks such as Facebook, Instagram, Twitter, and YouTube.",
    image: "/assets/project3.jpg",
    link: "/project3"
  },
];

const articles = [
  {
    title: "AI and Its Impact on the Future of Work",
    description: "Exploring how artificial intelligence is reshaping job markets and workplace dynamics.",
    image: "/assets/article1.jpg",
    link: "/article1"
  },
  {
    title: "How Machine Learning Models Actually Work",
    description: "Exploring the intricate mechanisms behind machine learning models, unraveling their complexity to reveal how they learn and make decisions..",
    image: "/assets/article2.jpg",
    link: "/article2"
  },
];

export default function Home() {
  return (
    <Box>
      <VideoBackground>
        <BackgroundVideo autoPlay loop muted>
          <source src="/assets/vid1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </BackgroundVideo>
      </VideoBackground>
      <AstronautImage src="/assets/astronaut.png" alt="Astronaut" />
      <Container maxWidth="lg">
        <ContentWrapper>
          <Fade in={true} timeout={1000}>
            <WelcomeBox>
              <Typography variant="h6" color="white">
                Welcome to my Portfolio
              </Typography>
            </WelcomeBox>
          </Fade>
          <Fade in={true} timeout={1500}>
            <Typography variant="h1" component="h1" color="white" sx={{ mb: 2, fontWeight: 'bold' }}>
              Hi! I'm Christophe Marcellus
            </Typography>
          </Fade>
          <Fade in={true} timeout={2000}>
            <Typography variant="h4" color="white" sx={{ mb: 4, maxWidth: '70%' }}>
              AI Enthusiast | Full-Stack Developer | Problem Solver
            </Typography>
          </Fade>
          <Fade in={true} timeout={2500}>
            <Typography variant="body1" color="white" sx={{ mb: 4, maxWidth: '60%' }}>
              Passionate about leveraging cutting-edge technology to solve complex problems.
              With expertise in AI, machine learning, and web development, I create innovative
              solutions that push the boundaries of what's possible in tech.
            </Typography>
          </Fade>
          <Fade in={true} timeout={3000}>
            <Box sx={{ mb: 8 }}>
              <Typography variant="h5" color="white" sx={{ mb: 2 }}>
                My Skills:
              </Typography>
              <Grid container spacing={1}>
                {skills.map((skill, index) => (
                  <Grid item key={index}>
                    <SkillChip
                      icon={skill.icon}
                      label={skill.name}
                      variant="outlined"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Fade>

          <Fade in={true} timeout={3500}>
            <Box sx={{ mb: 8 }}>
              <Typography variant="h2" color="white" sx={{ mb: 4 }}>
                My Projects
              </Typography>
              <Grid container spacing={4}>
                {projects.map((project, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <ProjectCard>
                      <CardMedia
                        component="img"
                        height="140"
                        image={project.image}
                        alt={project.title}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {project.title}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          {project.description}
                        </Typography>
                        <Button
                          component={Link}
                          to={project.link}
                          variant="outlined"
                          color="primary"
                        >
                          Learn More
                        </Button>
                      </CardContent>
                    </ProjectCard>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Fade>

          <Fade in={true} timeout={4000}>
            <Box sx={{ mb: 8 }}>
              <Typography variant="h2" color="white" sx={{ mb: 4 }}>
                Articles
              </Typography>
              <Grid container spacing={4}>
                {articles.map((article, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <ArticleCard>
                      <CardMedia
                        component="img"
                        height="200"
                        image={article.image}
                        alt={article.title}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {article.title}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          {article.description}
                        </Typography>
                        <Button
                          component={Link}
                          to={article.link}
                          variant="outlined"
                          color="primary"
                        >
                          Read More
                        </Button>
                      </CardContent>
                    </ArticleCard>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Fade>
        </ContentWrapper>
      </Container>
    </Box>
  );
}
