import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CodeIcon from '@mui/icons-material/Code';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Link } from 'react-router-dom';

const ProjectBackground = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(to bottom, #0f0c29, #302b63, #24243e)',
  minHeight: '100vh',
  color: 'white',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const ProjectCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  height: '100%',
}));

const FeatureIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const WhiteTextListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
    color: 'white',
  },
}));

export default function Project3() {
  const features = [
    "User Authentication with Firebase",
    "Profile Management",
    "OAuth Integration for Social Media Platforms",
    "Post Scheduling across Multiple Platforms",
    "Analytics and Reporting",
    "Responsive Design with Dark/Light Themes",
  ];

  const technologies = [
    "React with Vite for frontend development",
    "Tailwind CSS for styling",
    "Firebase Authentication and Firestore",
    "React Router for navigation",
    "OAuth for social media integration",
    "Netlify and Firebase Hosting for deployment",
  ];

  return (
    <ProjectBackground>
      <Container maxWidth="lg">
        <Button
          component={Link}
          to="/"
          startIcon={<ArrowBackIcon />}
          color="inherit"
          sx={{ mb: 4 }}
        >
          Back to Home
        </Button>

        <Typography variant="h2" component="h1" gutterBottom>
          AI-Powered Social Media Manager
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom>
              Project Overview
            </Typography>
            <Typography paragraph>
              The AI-Powered Social Media Manager is a comprehensive web application I've developed to revolutionize how individuals and businesses manage their social media presence. This project aims to simplify the process of managing multiple social media accounts, scheduling posts, and analyzing engagement metrics across various platforms.
            </Typography>
            <Typography paragraph>
              Leveraging modern web technologies and AI capabilities, this application provides a user-friendly interface for connecting multiple social media accounts, scheduling posts, and gaining insights into social media performance. The project integrates seamlessly with popular platforms such as Instagram, Facebook, Twitter, and YouTube, offering a centralized hub for all social media management needs.
            </Typography>
            <Typography paragraph fontWeight="bold">
              Ongoing Development
            </Typography>
            <Typography paragraph>
              This project is in active development, with continuous improvements and new features being added. I'm focusing on enhancing the AI-driven analytics, expanding platform integrations, and refining the user experience based on feedback and emerging social media trends.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <ProjectCard>
              <CardMedia
                component="img"
                height="300"
                image="/assets/project3.jpg"
                alt="Social Media Manager Dashboard"
              />
              <CardContent>
                <Typography variant="body2">
                </Typography>
              </CardContent>
            </ProjectCard>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              Key Features
            </Typography>
            <List>
              {features.map((feature, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <FeatureIcon />
                  </ListItemIcon>
                  <ListItemText primary={feature} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              Technologies Used
            </Typography>
            <List>
              {technologies.map((tech, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <FeatureIcon />
                  </ListItemIcon>
                  <ListItemText primary={tech} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} sm={4}>
            <ProjectCard>
              <CardMedia
                component="img"
                height="200"
                image="/assets/project31.jpg"
                alt="User Authentication Interface"
              />
              <CardContent>
                <Typography variant="body2">
                </Typography>
              </CardContent>
            </ProjectCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ProjectCard>
              <CardMedia
                component="img"
                height="200"
                image="/assets/project32.jpg"
                alt="Post Scheduling Interface"
              />
              <CardContent>
                <Typography variant="body2">

                </Typography>
              </CardContent>
            </ProjectCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ProjectCard>
              <CardMedia
                component="img"
                height="200"
                image="/assets/project33.jpg"
                alt="Analytics Dashboard"
              />
              <CardContent>
                <Typography variant="body2">

                </Typography>
              </CardContent>
            </ProjectCard>
          </Grid>
        </Grid>

        <Box sx={{ mt: 6 }}>
          <Typography variant="h5" gutterBottom>
            Development Highlights
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <AutoFixHighIcon color="primary" />
              </ListItemIcon>
              <WhiteTextListItemText
                primary="Authentication and Profile Management"
                secondary="Implemented secure user authentication using Firebase, with email/password and Google Sign-In options."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoFixHighIcon color="primary" />
              </ListItemIcon>
              <WhiteTextListItemText
                primary="Social Media Integration"
                secondary="Developed OAuth flows for connecting multiple social media platforms, securely storing access tokens in Firestore."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoFixHighIcon color="primary" />
              </ListItemIcon>
              <WhiteTextListItemText
                primary="Post Scheduling and Analytics"
                secondary="Created a robust scheduling system and implemented features to fetch and display engagement metrics for posts."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AutoFixHighIcon color="primary" />
              </ListItemIcon>
              <WhiteTextListItemText
                primary="Responsive UI with Theme Toggle"
                secondary="Designed a fully responsive UI using Tailwind CSS, including a dark/light theme toggle for user preference."
              />
            </ListItem>
          </List>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Future Enhancements
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CodeIcon color="secondary" />
              </ListItemIcon>
              <WhiteTextListItemText
                primary="Advanced AI-Driven Analytics"
                secondary="Implementing AI algorithms for deeper insights and personalized recommendations."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CodeIcon color="secondary" />
              </ListItemIcon>
              <WhiteTextListItemText
                primary="Collaboration Features"
                secondary="Adding multi-user collaboration capabilities with role-based access control."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CodeIcon color="secondary" />
              </ListItemIcon>
              <WhiteTextListItemText
                primary="Automated Posting Optimization"
                secondary="Developing AI-powered features to automatically schedule posts at optimal times."
              />
            </ListItem>
          </List>
        </Box>
      </Container>
    </ProjectBackground>
  );
}
