import React from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const ArticleBackground = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(to bottom, #0f0c29, #302b63, #24243e)',
  minHeight: '100vh',
  color: 'white',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const ArticleCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  marginBottom: theme.spacing(4),
}));

const ContentSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export default function Article2() {
  return (
    <ArticleBackground>
      <Container maxWidth="lg">
        <Button
          component={Link}
          to="/"
          startIcon={<ArrowBack />}
          color="inherit"
          sx={{ mb: 4 }}
        >
          Back to Home
        </Button>

        <ArticleCard>
          <CardMedia
            component="img"
            height="400"
            image="/assets/article2.jpg"
            alt="Machine Learning Models"
          />
          <CardContent>
            <Typography variant="h2" gutterBottom>
              How Machine Learning Models Actually Work
            </Typography>
            <Typography variant="subtitle1" color="text.primary" gutterBottom>
              Coming Soon | By Christophe Marcellus
            </Typography>
            <Divider sx={{ my: 2, backgroundColor: 'rgba(255,255,255,0.1)' }} />

            <ContentSection>
              <Typography variant="h4" gutterBottom>
                Article Coming Soon
              </Typography>
              <Typography variant="body1" paragraph>
                Stay tuned for an in-depth exploration of machine learning models.
              </Typography>
              <Typography variant="body1" paragraph>
                Topics to be covered:
              </Typography>
              <ul>
                <li>Basic principles of machine learning</li>
                <li>Different types of machine learning models</li>
                <li>How neural networks process information</li>
                <li>The mathematics behind popular algorithms</li>
                <li>Real-world applications and case studies</li>
              </ul>
              <Typography variant="body1" paragraph>
                Check back soon for this comprehensive guide to understanding machine learning models!
              </Typography>
            </ContentSection>
          </CardContent>
        </ArticleCard>
      </Container>
    </ArticleBackground>
  );
}
